<template>
  <v-dialog v-model="openTendersDialog" scrollable max-width="600px">
    <template v-slot:activator="{ on }">
  <v-hover v-slot:default="{ hover }">
    <v-btn
      variant="elevated"
      color="primary"
      class="dashboard-btn w-100 w-sm-auto"
      :elevation="hover ? 6 : 2"
      v-on="on"
    >
    Create Open Tender
    </v-btn>
  </v-hover>
</template>
    <v-card class="px-2 py-2">
      <v-card-title class="pb-3 px-2 px-sm-6">
        <v-row>
          <v-col cols="12" class="d-flex flex-column justify-center align-start py-3">
            <h2 class="main-title text-left text-break">
              Call for Tenders Details
            </h2>
          </v-col>
          <!-- <v-col
            cols="4"
            lg="2"
            class="d-flex flex-column justify-center align-end py-0"
          >
            <v-btn icon color="secondary" @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col> -->
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container class="px-0">
          <v-row class="mb-5">
            <v-col cols="12" class="py-0">
              <h2 class="subHeading secondary--text text-left pb-3 mt-3">
                Event Details
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field class="my-0" label="Event Name *" required outlined v-model="eventName"
                :error-messages="errorEventName"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field class="my-0" label="No. of Delegates" placeholder="1000 - 1500" v-model="noOfDelegates"
                :error-messages="errorNoOfDelegates" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <h4 class="text-left text-break">
                Preferred Area
              </h4>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-select class="my-0" label="Pref. Region " outlined required v-model="region" :items="regionList" multiple
                :error-messages="errorRegion" placeholder="Region"></v-select>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-autocomplete :items="getFilteredCountries" v-model="country" :allow-overflow="false" color="primary"
                item-color="primary" item-text="name" label="Pref. Country " :error-messages="errorCountry"
                clear-icon="mdi-close" clearable outlined multiple required chips small-chips deletable-chips
                :disabled="!region.length">
                <template v-slot:selection="{ attr, on, item, selected }">
                  <v-chip v-bind="attr" :input-value="selected" color="platinum" class="secondary--text" v-on="on">
                    <span v-text="item" class="text-truncate" style="max-width: 80%"></span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-autocomplete :items="getFilteredCities" v-model="city" :allow-overflow="false" color="primary"
                item-color="primary" item-text="name" label="Pref. City " :error-messages="errorCity"
                clear-icon="mdi-close" clearable outlined multiple required chips small-chips deletable-chips
                :disabled="!country.length">
                <template v-slot:selection="{ attr, on, item, selected }">
                  <v-chip v-bind="attr" :input-value="selected" color="platinum" class="secondary--text" v-on="on">
                    <span v-text="item" class="text-truncate" style="max-width: 80%"></span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <h4 class="text-left text-break">
                Preferred Dates
              </h4>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-menu v-model="fromMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field label="Preferred Start Date *" readonly outlined :placeholder="'Preferred Start Date'"
                    :value="preferredDate" v-bind="attrs" v-on="on" height="55px" :error-messages="errorPreferredDate"
                    @click="fromMenu = true">
                    <template v-slot:append>
                      <v-icon @click="fromMenu = true">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker class="datepicker" v-model="preferredDate" @input="fromMenu = false"
                  :min="new Date().toISOString().substr(0, 10)" no-title></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-menu v-model="endMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field label="Preferred End Date *" readonly outlined :placeholder="'Preferred End Date'"
                    :value="preferredEndDate" v-bind="attrs" v-on="on" height="55px"
                    :error-messages="errorPreferredEndDate" @click="endMenu = true">
                    <template v-slot:append>
                      <v-icon @click="endMenu = true">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker class="datepicker" v-model="preferredEndDate" @input="endMenu = false"
                  :min="preferredDate" no-title></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="8" class="py-0">
              <v-autocomplete :items="months" v-model="prefMonths" :allow-overflow="false" color="primary"
                item-color="primary" item-text="name" label="Pref. Months" clear-icon="mdi-close" clearable outlined
                multiple required chips small-chips deletable-chips>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" class="mb-2" style="margin-top: -20px;">
              <v-checkbox v-model="flexible" label="Flexible" color="primary" hide-details></v-checkbox>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <v-row>
                <v-col cols="12">
                  <v-text-field class="my-0" outlined v-model="prefYear" label="Pref. Years"
                    @keydown.enter="addPrefYears"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="8" class="py-0 justify-end" style="justify-content: right;">
              <v-chip v-for="(item, index) in prefYears" :key="index" @click="removePrefYear(index)" color="platinum"
                style="margin-left: 2px;cursor: pointer;margin-top: 2px;" class="secondary--text">
                {{ item }}
                <v-icon small dark>mdi-close</v-icon>
              </v-chip>
              <h5 class="text-left text-break" v-if="prefYears.length === 0">
                Please enter your preferred year and press Enter. You can add multiple years in this manner.
              </h5>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field class="my-0" label="No. of Days" outlined v-model="noOfDays" :error-messages="errorNoOfDays"
                @keypress=";[isNumber()]"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field class="my-0" label="Company Website *" outlined required v-model="website"
                :error-messages="errorWebsite"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- <v-text-field
                class="my-0"
                label="Proposal Deadline*"
                outlined
                required
                v-model="deadline"
                :error-messages="errorDeadline"
              ></v-text-field> -->
              <v-menu v-model="toMenu" :close-on-content-click="false" transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field label="Proposal Deadline *" readonly outlined :placeholder="'Proposal Deadline *'"
                    :value="deadline" v-bind="attrs" v-on="on" height="55px" @click="toMenu = true"
                    :error-messages="errorDeadline">
                    <template v-slot:append>
                      <v-icon @click="toMenu = true">mdi-calendar</v-icon>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker class="datepicker" v-model="deadline" @input="toMenu = false"
                  :min="new Date().toISOString().substr(0, 10)" no-title></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <!-- <v-select
                class="my-0"
                label="Topics"
                outlined
                required
                v-model="topics"
                :items="topicList"
                :error-messages="errorTopic"
                multiple
              ></v-select> -->
              <v-autocomplete :items="topicList" v-model="topics" :allow-overflow="false" color="primary"
                item-color="primary" item-text="name" label="Topics" :error-messages="errorTopic" clear-icon="mdi-close"
                clearable outlined multiple required chips small-chips deletable-chips>
                <template v-slot:selection="{ attr, on, item, selected }">
                  <v-chip v-bind="attr" :input-value="selected" color="platinum" class="secondary--text" close
                    close-icon="mdi-close" v-on="on">
                    <span v-text="item" class="text-truncate" style="max-width: 80%"></span>
                  </v-chip>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-3"></v-divider>
          <v-row class="mt-4 mb-2">
            <v-col cols="12" class="py-0">
              <h2 class="subHeading secondary--text text-left pb-3">
                Contact Details
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field class="my-0" label="Full Name *" outlined required v-model="memberName"
                :error-messages="errorMemberName"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <v-text-field class="my-0" @keypress=";[isNumber()]" label="Mobile" outlined required v-model="mobile"
                :error-messages="errorMobile"></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" class="py-0">
              <v-text-field class="my-0" label="Email *" outlined required v-model="email" :rules="email ? checkEmailFn(email) : []"
                :error-messages="errorEmail"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field class="my-0" label=" Position" outlined required v-model="memberDesignation"
                :error-messages="errorMemberDesignation"></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-3"></v-divider>
          <v-row class="mt-4 mb-2">
            <v-col cols="6" class="py-0">
              <h2 class="subHeading secondary--text text-left pb-3 mb-3">
                Support Document/s
              </h2>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-file-input label="Choose Document *" placeholder="Choose Document *" prepend-icon
                    append-icon="mdi-paperclip" clear-icon="mdi-close" truncate-length="14" clearable outlined required
                    accept="application/pdf" hide-details="auto" v-model="document"
                    :error-messages="errorDocument"></v-file-input>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="py-0">
              <h2 class="subHeading secondary--text text-left pb-3 mb-3">
                Support Image
              </h2>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-file-input label="Choose Image" placeholder="Choose Cover Image" prepend-icon
                    append-icon="mdi-paperclip" clear-icon="mdi-close" truncate-length="14" clearable show-size outlined
                    required accept="image/*" hide-details="auto" v-model="selectedImage"
                    :error-messages="errorImage"></v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- <v-row>
            <CallForTenderDetails ref="CallForTenderDetails" :trigger="triggerDetails" />
            <v-col cols="12" sm="12" class="py-0">
              <v-row class="d-flex flex-row align-center justify-space-between mx-0 mt-8 mb-6">
                <a @click="openPopup" class="call-for-tenders-link">View your previous tenders >></a>
              </v-row>
            </v-col>
          </v-row> -->
        </v-container>
      </v-card-text>
      <v-card-actions class="px-6">
        <v-row class="d-flex flex-column-reverse flex-sm-row align-center justify-space-between mx-0">
          <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-start px-0">
            <v-btn class="px-8" color="secondary" @click="closeDialog" tile outlined depressed bottom>
              Cancel
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3" class="d-flex flex-column align-center align-sm-end px-0">
            <v-btn class="px-8" color="secondary" @click="submitFn" :loading="apiContactLoader" tile depressed bottom
              right>
              Submit
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import CallForTenderDetails from '@/components/OrganiserDashboard/CallForTenderDetails.vue'
import isNumber from '@/mixin/IsNumber'
import Tenders from '@/services/tender.service'
// import cityServices from '@/services/city.service'
// import DateFormatYYMMDD from '@/Mixin/DateFormatYYMMDD'

export default {
  name: 'TenderOrganiser',
  mixins: [isNumber],
  components: {
    // CallForTenderDetails
  },
  data() {
    return {
      triggerDetails: false,
      email: null,
      errorEmail: null,
      isApiLoad: false,
      openTendersDialog: false,
      eventName: null,
      noOfDelegates: null,
      region: [],
      country: [],
      city: [],
      prefYears: [],
      prefYear: null,
      prefMonths: [],
      flexible: false,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      preferredDate: null,
      preferredEndDate: null,
      noOfDays: null,
      website: null,
      deadline: null,
      memberName: null,
      memberDesignation: null,
      mobile: null,
      document: null,
      selectedImage: null,
      topics: [],
      fromMenu: null,
      endMenu:null,
      toMenu: null,
      errorEventName: null,
      errorNoOfDelegates: null,
      errorCountry: null,
      errorRegion: null,
      errorCity: null,
      errorPreferredDate: null,
      errorpreferredEndDate: null,
      errorNoOfDays: null,
      errorWebsite: null,
      errorDeadline: null,
      errorTopic: null,
      errorMemberName: null,
      errorMemberDesignation: null,
      errorMobile: null,
      errorDocument: null,
      errorImage: null,
      isCreatingNewTender: true,
      apiContactLoader: false,
      directContactId: null,
      regionList: ['Europe', 'Asia', 'North-America', 'Middle-East'],
      countryList: [],
      cityList: [],
      topicList: [
        'Sustainability',
        'Biology',
        'Construction',
        'Creative',
        'Ecology',
        'Economics',
        'Education',
        'Energy',
        'Geography',
        'History',
        'Manufacturing and Industry',
        'Law',
        'Linguistics',
        'Mathematics & Statistics',
        'Pharma, Healthcare & Medicine',
        'Safety & Security',
        'Science',
        'Social Science',
        'Sports, Leisure & Culture',
        'Technology',
        'Transport',
        'Shipping and Maritime',
        'Finance & Banking',
        'Information and Communication Technology',



      ],
      staticErrorMessages: {
        eventName: {
          isEmpty: 'Event Name should not be empty'
        },
        noOfDelegates: {
          isEmpty: 'No. of Delegates should not be empty'
        },
        email: {
          isEmpty: 'Email should not be empty',
          invalidEmail: 'Invalid Email address'
        },
        country: {
          isEmpty: 'Preferred country should not be empty'
        },
        region: {
          isEmpty: 'Preferred region should not be empty'
        },
        city: {
          isEmpty: 'Preferred city should not be empty'
        },
        preferredDate: {
          isEmpty: 'Preferred Start Date should not be empty'
        },
        preferredEndDate: {
          isEmpty: 'Preferred End Date should not be empty'
        },
        noOfDays: {
          isEmpty: 'No. of Days should not be empty'
        },
        website: {
          isEmpty: 'Company Website should not be empty'
        },
        deadline: {
          isEmpty: 'Proposal Deadline should not be empty'
        },
        topics: {
          isEmpty: 'Topics should not be empty'
        },
        memberName: {
          isEmpty: 'Member Name should not be empty'
        },
        memberDesignation: {
          isEmpty: 'Member Designation should not be empty'
        },
        mobile: {
          isEmpty: 'Mobile should not be empty'
        },
        document: {
          isEmpty: 'Document should not be empty'
        },
        selectedImage: {
          isEmpty: 'Image should not be empty'
        }
      }
    }
  },
  computed: {
    getFilteredCities() {
      if (this.country && this.country.length > 0) {
        const selectedCities = this.cityList
          .filter(item => this.country.includes(item.country))
          .map(item => item.city)
        return Array.from(new Set(selectedCities))
      } else {
        return []
      }
    },
    getFilteredCountries() {
      if (this.region && this.region.length > 0) {
        const res = this.countryList.filter(item => this.region.includes(item.continent))
          .map(item => item.country);
        return res;
      }
      return [];
    }
  },
  watch: {
    eventName() {
      this.errorEventName = null
    },
    noOfDelegates() {
      this.errorNoOfDelegates = null
    },
    country() {
      this.errorCountry = null
    },
    region() {
      this.errorRegion = null;
    },
    city() {
      this.errorCity = null
    },
    preferredDate() {
      this.errorPreferredDate = null
    },
    preferredEndDate() {
      this.errorPreferredEndDate = null
    },
    noOfDays() {
      this.errorNoOfDays = null
    },
    website() {
      this.errorWebsite = null
    },
    deadline() {
      this.errorDeadline = null
    },
    topics() {
      this.errorTopic = null
    },
    memberName() {
      this.errorMemberName = null
    },
    memberDesignation() {
      this.errorMemberDesignation = null
    },
    mobile() {
      this.errorMobile = null
    },
    email() {
      this.errorEmail = null
    },
    document() {
      this.errorDocument = null
    },
    selectedImage() {
      this.errorImage = null
    }
  },
  mounted() {
    this.getCountriesAndCities()
  },
  methods: {
    resetForm() {
    // Reset all form fields to initial values
    this.eventName = null
    this.noOfDelegates = null
    this.country = []
    this.region = []
    this.city = []
    this.preferredDate = null
    this.preferredEndDate = null
    this.prefMonths = []
    this.prefYear = null
    this.prefYears = []
    this.flexible = false
    this.noOfDays = null
    this.website = null
    this.deadline = null
    this.topics = null
    this.memberName = null
    this.memberDesignation = null
    this.mobile = null
    this.email = null
    this.document = null
    this.selectedImage = null

    // Reset all error messages
    this.errorEventName = null
    this.errorNoOfDelegates = null
    this.errorCountry = null
    this.errorRegion = null
    this.errorCity = null
    this.errorPreferredDate = null
    this.errorPreferredEndDate = null
    this.errorNoOfDays = null
    this.errorWebsite = null
    this.errorDeadline = null
    this.errorTopic = null
    this.errorMemberName = null
    this.errorMemberDesignation = null
    this.errorMobile = null
    this.errorEmail = null
    this.errorDocument = null
    this.errorImage = null

    // Reset menus
    this.fromMenu = null
    this.endMenu = null
    this.toMenu = null
  },
    removePrefYear(index) {
      this.prefYears.splice(index, 1);
    },
    addPrefYears() {
      if (!isNaN(this.prefYear) && this.prefYear.trim().length === 4) {
        if (this.prefYears.includes(this.prefYear)) {
          this.prefYear = '';
          let snackbarPayload = {
            snackBarText: 'This year has already been selected. Please choose a different year.',
            snackBarColor: 'secondary'
          }
          this.$store.dispatch('setSnackbarMessage', snackbarPayload)
          return;
        }
        if (this.prefYear.trim() !== '') {
          this.prefYears.push(this.prefYear.trim());
          this.prefYear = '';
        }
      } else {
        let snackbarPayload = {
          snackBarText: 'Preferred year must be a four-digit number and cannot contain any symbols or characters other than digits (0-9)',
          snackBarColor: 'error'
        }
        this.$store.dispatch('setSnackbarMessage', snackbarPayload)
      }
    },
    // openPopup() {
    //   this.$refs.CallForTenderDetails.dialog = true
    //   this.triggerDetails = true
    // },
    closeDialog() {
      this.openTendersDialog = false
      this.triggerDetails = false
      this.resetForm()
    },
    // checkEmailFn(email) {
    //   if (email && email !== "") {
    //     var pattern = new RegExp(
    //       /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    //     );
    //     return [pattern.test(email) || "Invalid Email address"];
    //   } else {
    //     return [false];
    //   }
    // },
    checkEmailFn(email) {
      if (!email) {
        return [true]
      }
      const pattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
      return [pattern.test(email) || "Invalid Email address"]
    },
    capitalize(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    getCountriesAndCities() {
      Tenders.getAllCityV4().then(res => {
        const details = res.data.data
        if (details) {
          this.cityList = details.map(item => ({
            continent: this.capitalize(item.continent),
            country: this.capitalize(item.country),
            city: this.capitalize(item.city)
          }))

          const countries = new Set(this.cityList.map(item => ({ country: item.country, continent: item.continent })))
          this.countryList = Array.from(countries)
        }
      })
    },
    async submitFn() {
      const isEmailValid = this.checkEmailFn(this.email)
      if (
        this.eventName &&
        // this.noOfDelegates &&
        // this.country &&
        // this.city &&
        this.preferredDate &&
        this.preferredEndDate &&
        // this.noOfDays &&
        this.website &&
        this.deadline &&
        // this.topics &&
        this.memberName &&
        // this.memberDesignation &&
        // this.mobile &&
        isEmailValid[0] === true &&
        this.document
      ) {
        this.apiContactLoader = true
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
          })
        try {
          let payload = {
            img: ''
          }
          if (this.selectedImage) {
            const payloadImg = {
              Path: `${JSON.parse(localStorage.getItem('admin')).admin._id +"-"+ Date.now() +"-"+ this.eventName.replace(/[^a-zA-Z]/g, '').toLowerCase()}.jpg`,
              Base64: await toBase64(this.selectedImage)
            }
            const imgResponse = await Tenders.imageUpload(payloadImg)
            payload.img = imgResponse.data.content
              ? imgResponse.data.content
              : ''
          }

          var formData = new FormData()
          formData.append('file', this.document)
          let payload2
          if (this.document) {
            await Tenders.uploadFile(formData).then(res => {
              payload2 = {
                file: res.data.details
              }
            })
          } else {
            payload2 = null
          }

          let payload3 = {
            EeventName: this.eventName,
            noOFDelegates: this.noOfDelegates,
            country: this.country,
            region: this.region,
            city: this.city,
            preferredDate: this.preferredDate+' To '+this.preferredEndDate,
            prefMonths: this.prefMonths,
            prefYears: this.prefYears,
            flexible: this.flexible,
            noOFDays: this.noOfDays,
            companyWeb: this.website,
            proposalDedline: this.deadline,
            topics: this.topics,
            contactName: this.memberName,
            contactMobile: this.mobile ? this.mobile : '',
            contactEmail: this.email,
            contactDesignation: this.memberDesignation,
            supportFile: payload2 ? payload2.file : '',
            supportImage: payload.img,
            organiserId: null
          }

          Tenders.createCallForTender(payload3)
          let snackbarPayload = {
            snackBarText: 'Call For Tender has been added successfully',
            snackBarColor: 'success'
          }
          this.$store.dispatch('notify/setSnackbarMessage', snackbarPayload)
          this.openTendersDialog = false
          this.apiContactLoader = false
          // this.eventName = null
          // this.noOfDelegates = null
          // this.country = []
          // this.region = []
          // this.city = null
          // this.preferredDate = null
          // this.preferredEndDate = null
          // this.prefMonths = [];
          // this.prefYear = '';
          // this.prefYears = [];
          // this.flexible = false;
          // this.noOfDays = null
          // this.website = null
          // this.deadline = null
          // this.topics = null
          // this.memberName = null
          // this.memberDesignation = null
          // this.mobile = null
          // this.email = null
          // this.errorEmail = null
          // this.document = null
          // this.selectedImage = null
          this.resetForm()

          this.$emit('tender-added')
          
        } catch (error) {
          console.error('Error:', error)
          this.apiContactLoader = false
        }
      } else {
        if (!this.eventName) {
          this.errorEventName = this.staticErrorMessages.eventName.isEmpty
        }
        if (!this.noOfDelegates) {
          // this.errorNoOfDelegates = this.staticErrorMessages.noOfDelegates.isEmpty
        }
        if (!this.country & this.country.length === 0) {
          this.errorCountry = this.staticErrorMessages.country.isEmpty
        }
        if (!this.city & this.city.length === 0) {
          this.errorCity = this.staticErrorMessages.city.isEmpty
        }
        if (!this.preferredDate) {
          this.errorPreferredDate = this.staticErrorMessages.preferredDate.isEmpty
        }
        if (!this.preferredEndDate) {
          this.errorPreferredEndDate = this.staticErrorMessages.preferredEndDate.isEmpty
        }
        if (!this.noOfDays) {
          // this.errorNoOfDays = this.staticErrorMessages.noOfDays.isEmpty
        }
        if (!this.website) {
          this.errorWebsite = this.staticErrorMessages.website.isEmpty
        }
        if (!this.deadline) {
          this.errorDeadline = this.staticErrorMessages.deadline.isEmpty
        }
        if (!this.topics) {
          // this.errorTopic = this.staticErrorMessages.topics.isEmpty
        }
        if (!this.memberName) {
          this.errorMemberName = this.staticErrorMessages.memberName.isEmpty
        }
        if (!this.memberDesignation) {
          // this.errorMemberDesignation = this.staticErrorMessages.memberDesignation.isEmpty
        }
        if (!this.mobile) {
          // this.errorMobile = this.staticErrorMessages.mobile.isEmpty
        }
        if (!this.email) {
          this.errorEmail = this.staticErrorMessages.email.isEmpty
        } else if (!isEmailValid[0]) {
          this.errorEmail = this.staticErrorMessages.email.invalidEmail
        } else {
          this.errorEmail = null
        }
        if (!this.document) {
          this.errorDocument = this.staticErrorMessages.document.isEmpty
        }
        if (!this.selectedImage) {
          // this.errorImage = this.staticErrorMessages.selectedImage.isEmpty
        }
      }
    },
    isPDF(file) {
      const regex = /\.(pdf)$/
      return regex.test(file.name)
    }
  }
}
</script>

<style scoped>
.dashboard-card {
  cursor: pointer;
}

.dashboard-card .dashboard-card-icon {
  color: var(--v-primary-base);
  opacity: 0.7;
  transition: color 0.3s ease-in-out;
}

.dashboard-card .dashboard-card-icon:not(.on-hover) {
  color: var(--v-platinum-base);
  opacity: 1;
}

.tender-text {
  line-height: 1rem !important;
}

.call-for-tenders-link {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.subHeading {
  font-size: 18px;
  font-weight: 500;
  font-family: 'TTHoves-Medium', 'Open Sans', sans-serif !important;
}

.main-title {
  font-size: 32px;
  font-family: 'TTHoves-medium', 'Open Sans', sans-serif !important;
  font-weight: 500;
}

.test {
  color: #009246;
  font-family: 'TTHoves-light', 'Open Sans', sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 75% */
  letter-spacing: 0.15px;
}

.info-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
}

.info-tooltip-text {
  display: inline-block;
  max-width: 256px;
  text-align: center;
}

.datepicker {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* Medium devices (large tablet to laptop, less than 960px) */
@media (max-width: 960px) {

  /* change text size on smaller screens */
  .v-application .main-title {
    font-size: 1.3rem !important;
    line-height: 1.3rem;
  }

  .v-application .subHeading {
    font-size: 1.1rem !important;
    line-height: 1.1rem;
  }

  .v-application .caption {
    font-size: 0.7rem !important;
    line-height: 1rem;
  }
}

/* Extra small devices (small to large handset, less than 600px) */
@media (max-width: 600px) {

  /* change text size on smaller screens */
  .v-application .main-title {
    font-size: 1rem !important;
    line-height: 1rem;
  }

  .v-application .subHeading {
    font-size: 0.9rem !important;
    line-height: 0.9rem;
  }

  .v-application .caption {
    font-size: 0.6rem !important;
    line-height: 1rem;
  }
}
</style>
