<template>
  <!-- eslint-disable -->
  <v-container id="rfp-page" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card color="primary" class="px-5 py-3">
          <template v-slot:heading>
            <div class="display-2 font-weight-light">Call for Tenders</div>
            <div class="subtitle-1 font-weight-light">
              A List of All Tenders
            </div>
          </template>
          <v-card-text>
            <v-row
              class="mx-0"
              align="center"
              justify="center"
              justify-md="start"
            >
            <v-row class="pt-3 d-flex justify-space-between align-center">
              <v-col cols="12" sm="7" md="7" lg="5" class="pl-5">
                <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                outlined
                hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4" class="pl-5 d-flex justify-sm-end mt-sm-0">
                <CallForTenders @tender-added="getAllTenders"/>
              </v-col>
            </v-row>
              <v-col cols="12" class="px-0">
                <v-data-table
                  :headers="headers"
                  :search="search"
                  :items="items"
                  :loading="isLoading"
                >
                  <template v-slot:item.circle="{ item }">
                    <div v-if="item.isNew" class="circle-icon"></div>
                  </template>

                  <template v-slot:[`item.preferredDate`]="{ item }">
                    {{ item.preferredDate.includes('To')?item.preferredDate:DateFormatYYMMDD(item.preferredDate) }}
                  </template>
                  <template v-slot:[`item.proposalDedline`]="{ item }">
                    {{ DateFormatYYMMDD(item.proposalDedline) }}
                  </template>
                  <template v-slot:item.more="{ item }">
                    <v-btn icon color="grey lighten-1" @click="RouteFn(item._id)">
                      <font-awesome-icon :icon="['fas', 'chevron-right']"/>
                    </v-btn>
                  </template>
                  <template v-slot:item.cityOrCountry="{ item }">
                    <p>{{
                        item.country && item.country.length > 0 ? item.country[0] : item.city && item.city.length > 0 ? item.city[0] : ''
                      }}</p>
                  </template>
                </v-data-table>

                <!-- </v-data-table> -->
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateFormatYYMMDD from "../../../mixin/DateFormatYYMMDD";
import Tenders from "../../../services/tender.service";
import CallForTenders from "./CallForTenders.vue";

export default {
  name: "RFP",
  components: {CallForTenders,},
  data() {
    return {
      search: "",
      dialog: false,
      items: [],
      EeventName: null,
      mobile: null,
      email: null,
      company: null,
      address: null,
      vatNo: null,
      errorName: null,
      erroMobile: null,
      errorEmail: null,
      errorCompany: null,
      errorAddress: null,
      errorVatNo: null,
      fromDate: null,
      toDate: null,
      errorStartDate: null,
      errorEndDate: null,
      approve: false,
      reject: false,
      isLoading: false,
      fromMenu: null,
      toMenu: null,
      isApiLoader: null,
      selectedItem: {},
    };
  },
  mounted() {
    // this.isLoading = true;
    // Tenders.getAllCallForTenders().then((res) => {
    //   this.items = res.data.map((x) => {
    //     return {
    //       ...x,
    //     };
    //   });
    //   this.isLoading = false;
    // });
    // this.isLoading = false;
    this.getAllTenders();
  },
  computed: {
    headers() {
      return [
        {
          sortable: false,
          text: "",
          value: "circle",
          class: "primary--text title",
          align: "end",
        },
        {
          sortable: true,
          text: "Name",
          value: "EeventName",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Preferred Dates",
          value: "preferredDate",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Company Web",
          value: "companyWeb",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "City/Country",
          value: "cityOrCountry",
          class: "primary--text title",
        },
        {
          sortable: true,
          text: "Deadline",
          value: "proposalDedline",
          class: "primary--text title",
        },
        {
          sortable: false,
          text: "More",
          value: "more",
          class: "primary--text title",
        },
      ];
    },
  },
  methods: {
    DateFormatYYMMDD,
    RouteFn(_id) {
      this.$router.push(`call-for-tenders/${_id}`);
      console.log(_id);
    },
    closeDialog() {
      this.dialog = false;
    },
    // async getAllTenders() {
    //   this.isLoading = true;
    //   try {
    //     const res = await Tenders.getAllCallForTenders();
    //     this.items = res.data.map(x => ({
    //       ...x,
    //     }));
    //     console.log("Tender Data : ",res.data)
    //   } finally {
    //     this.isLoading = false;
    //   }
    // }
    async getAllTenders() {
      this.isLoading = true;
      try {
        const res = await Tenders.getAllCallForTenders();
        this.items = res.data
        .map(x => ({ ...x }))
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } finally {
        this.isLoading = false;
      }
    }
  },
};
</script>
<style scoped>
.circle-icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #35a7ff;
}

.date-picker {
  width: 100%;
}

.v-menu__content .v-date-picker {
  max-width: none;
}

.v-date-picker {
  max-width: none !important;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
}

.hr-divider {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--v-platinum-base);
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}

.datepicker {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.typeText {
  color: #35a7ff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
</style>
